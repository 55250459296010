//
// card.scss
//

.card {
  border: none;
  box-shadow: $card-box-shadow;
  margin-bottom: $grid-gutter-width;
  border-radius: 0.25rem;

  &.border-card {
    border: 1px solid #ccc;
  }

  &.list-card {
    .list {
      max-height: 400px;
      overflow-y: scroll;
    }
  }

  .card-title {
    padding: 15px;
    background-color: $gray-900;
    color: #fff;
    margin: 0;
    border-radius: 0.25rem 0.25rem 0 0;

    &.gradient {
      background-image: url('../../images/gradient.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0;

      .overlay {
        padding: 15px;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .card-body {
    padding: 15px;
  }
}
