//
// tables.scss
//

.table {
    background: #FFF;
    margin: 0;

    &.no-border {
        th, td {
            border: none;
        }
    }

    &.no-padding {
        th, td {
            padding: 0;
        }
    }

    &.minimized {
        tr {
            &:first-child {
                td {
                    padding-top: 0;
                }
            }

            &:last-child {
                td {
                    padding-bottom: 0;
                }
            }

            td {
                font-size: .8rem;
                padding: 10px;

                &:first-child {
                    font-weight: bold;
                    padding-left: 0;
                }

                &:last-child {
                    text-align: right;
                    padding-right: 0;
                }
            }
        }
    }

    thead {
        background-color: $gray-900;
        color: #FFF;

        th {
            border: none;
            padding: 15px;
            vertical-align: middle;
            font-size: 0;

            &:first-child {
                border-top-left-radius: 0.25rem;
            }

            &:last-child {
                border-top-right-radius: 0.25rem;
            }
        }
    }

    tbody {
        tr, .table-row {
            background-color: #FFF;
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;

            @include transition(0.2s ease-in-out background-color);

            &:not(.no-hover):hover {
                background-color: #F6F6F6;
            }

            &:first-child {
                td {
                    border-top: none;
                }
            }

            &.clickable {
                td {
                    cursor: pointer;
                }
            }

            td {
                padding: 15px;
                vertical-align: middle;

                &.icons-cell {
                    i {
                        font-size: 20px;
                    }
                }

                &.loading-cell {
                    padding: 50px 15px;
                    text-align: center;

                    .icon-holder {
                        i {
                            font-size: 4rem;
                            line-height: 100%;
                        }
                    }
                }

                &.circle-cell {
                    > * {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .circle {
                        margin-right: 10px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: $gray-200;
                        font-size: 0;
                        text-align: center;

                        &.green {
                            @include bg-variant-light($green);

                            i {
                                color: $green;
                            }
                        }

                        i {
                            font-size: 25px;
                            line-height: 50px;
                            color: $gray-600;
                        }
                    }
                }
            }
        }
    }
}

table.table-light thead {
    color: #111 !important;

    th {
        font-size: 0.9rem;
    }
}