//
// navbar.scss
//

.navbar-custom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  margin: 0;
  box-shadow: $shadow;
  height: $navbar-height;
  background: #111;

  > .content {
    position: relative;
    padding: 0 15px;

    .overlay {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: $navbar-height;
      background: rgba(0, 0, 0, 0.2);
    }

    .side-menu-icon {
      float: left;
      display: none;
      margin-right: 15px;
      cursor: pointer;

      i {
        color: #fff;
        font-size: $navbar-height * 0.67;
        line-height: $navbar-height;
      }
    }

    .navbar-logo {
      height: $navbar-height;
      float: left;
      display: flex;
      align-items: center;
      font-size: 0;
      margin-right: 25px;

      .logo-icon {
        img {
          height: $navbar-logo-height;
          width: auto;
          margin-right: 10px;
        }
      }

      .logo-text {
        font-size: $navbar-logo-height - 5px;
        color: #fff;
        line-height: $navbar-logo-height;
      }
    }

    form.app-search {
      padding: calc(#{$navbar-height - 38px} / 2) 0;
      overflow: hidden;
      display: block;
      width: 350px;
      margin: 0;

      .form-control {
        border: none;
        height: 38px;
        padding-left: 40px;
        padding-right: 20px;
        box-shadow: none;
        background-color: $bg-navbar-search;
        color: $white;
        border-radius: 0.25rem !important;
      }

      span {
        color: $gray-200;
        position: absolute;
        z-index: 9;
        font-size: 20px;
        line-height: 38px;
        left: 10px;
        top: 0;
      }
    }
  }
}

// Responsiveness for side menu
@media screen and (max-width: 900px) {
  .navbar-custom > .content .side-menu-icon {
    display: block;
  }
}
