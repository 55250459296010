//
// _assembly.scss
//

$step-circle-size: 75px;

.assembly-demo-page {
    .stats-card {
        .stats-list {
            .stat-item {
                margin: 2rem 0;
                text-align: center;

                .value {
                    font-size: 5rem;
                    line-height: 100%;
                }

                .label {
                    margin-top: 2.5px;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 400;
                }
            }
        }
    }

    .current-cycle-card {
        .steps-list {
            font-size: 0;
            display: flex;
            align-items: top;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 15px 0;

            .step-item {
                text-align: center;
                margin: 15px 0;
                width: 25%;

                &.active {
                    .circle {
                        background: $green;
                        color: #FFF;
                    }
                }

                .circle {
                    border-radius: 50%;
                    width: $step-circle-size;
                    height: $step-circle-size;
                    margin: 0 10px;
                    background: $gray-200;
                    font-weight: bold;
                    font-size: $step-circle-size / 2;
                    line-height: $step-circle-size;
                    margin: 0 auto;
                }

                .value {
                    margin-top: 10px;
                    font-weight: bold;
                    font-size: 1rem;
                }
            }
        }
    }

    .previous-cycle-card {
        .left-item {
            text-align: center;
        }

        .icon-holder {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: $gray-200;
            color: $gray-600;
            margin: 0 auto;

            &.green {
                background: $green;
                color: #FFF;
            }

            &.red {
                background: $red;
                color: #FFF;
            }

            i {
                line-height: 100px;
                font-size: 50px;
            }
        }
    }
}
