// Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "config/variables";
@import "config/custom-variables";
@import "./node_modules/bootstrap/scss/bootstrap";

// Fonts
@import "fonts/circular";

// Plugins
@import "plugins/icons";
@import "plugins/chartjs";
@import "plugins/react-select";
@import "plugins/typehead";

// Structure
@import "structure/general";
@import "structure/navbar";
@import "structure/side_menu";
@import "structure/login";

// Bootstrap/hyper-react components
@import "components/mixins";
@import "components/badge";
@import "components/buttons";
@import "components/card";
@import "components/dropdown";
@import "components/forms";
@import "components/modal";
@import "components/preloader";
@import "components/reboot";
@import "components/tables";
@import "components/type";
@import "components/widgets";

// Invisible AI components
@import "components/page_title";
@import "components/hero_section";
@import "components/list";
@import "components/home/welcome_card";
@import "components/device/app_card";
@import "components/device/app_config_modal";
@import "components/device/new_example_modal";
@import "components/device/triggers_modal";
@import "components/device/edit_config_modal";
@import "components/demo_mode/assembly";
@import "components/dashboard/stats_card";
@import "components/dashboard/list_card";
@import "components/create_process_config";
@import "components/versions/version-row";
@import "components/debug_events/debug-events-row";
