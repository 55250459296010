//
// mixins.scss
//

// Background lighten
@mixin bg-variant-light($color) {
    background-color: rgba($color, 0.25) !important;
}

// Color lighten
@mixin color-variant-light($color) {
    color: rgba($color, 0.25) !important;
}

// Button shadow
@mixin button-shadow($color) {
    box-shadow: $btn-box-shadow rgba($color, 0.5);
}

// Lighten badge
@mixin badge-variant-light($bg) {
    color: $bg;
    background-color: rgba($bg, 0.18);

    &[href] {
        @include hover-focus {
            color: $bg;
            text-decoration: none;
            background-color: rgba($bg, 0.4);
        }
    }
}

// Outline badge
@mixin badge-variant-outline($bg) {
    color: $bg;
    border: 1px solid $bg;
    background-color: transparent;

    &[href] {
        @include hover-focus {
            color: $bg;
            text-decoration: none;
            background-color: rgba($bg, 0.2);
        }
    }
}

// Box shadow
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $color;
        -moz-box-shadow:inset $top $left $blur $color;
        -o-box-shadow:inset $top $left $blur $color;
        box-shadow:inset $top $left $blur $color;
    } @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        -o-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}

// Transition
@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

