//
// modal.scss
//

// My customizations
.modal-backdrop.show {
    opacity: 0.85;
}

.modal-content {
    background-color: transparent;
}

.modal-header-custom {
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: $gray-200;
    border-bottom: 0;
    padding: 15px;
    position: relative;

    .modal-title-custom {
        margin: 0;
    }

    .close-custom {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 15px;
    }
}

.modal-body {
    background-color: #FFF;
    padding: 15px;
    border-radius: 0 0 0.25rem 0.25rem;

    &.no-header {
        border-radius: 0.25rem;
    }
}

.modal-footer {
    justify-content: flex-start;
}

// Full screen width
.modal-full-screen {
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;

    .modal-content {
        border-radius: 0;
        height: 100%;

        .modal-header-custom {
            border-radius: 0;
        }

        .modal-body {
            border-radius: 0;
            height: 100%;
        }
    }
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}

// Right Modal
.modal-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: $modal-content-bg;
    align-content: center;
    transform: translate(25%,0) !important;

    button.close {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1;
    }
}

.modal {
    &.show {
        .modal-right {
            transform: translate(0,0) !important;
        }
    }
}

// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .close {
        color: $white !important;
    }
}

// Full filled modals
.modal-filled {
    color: $white;

    .modal-header {
        background-color: rgba($white,0.07);
    }

    .modal-header,.modal-footer {
        border: none;
    }

    .close {
        color: $white !important;
    }
}

.modal-50 {
    width: 50vw;
    max-width: 100000px !important;
}

.lg-warning-icon {
    font-size: 45px;
    padding-right: 15px;
  }
