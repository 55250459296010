//
// login.scss
//

.login-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
  justify-content: flex-start;

  .banner-side,
  .form-side {
    flex-grow: 1;
    position: relative;
  }

  .banner-side {
    background-image: url('../../images/banner1.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .overlay {
      background: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      height: 100%;

      > .content {
        color: #fff;
        max-width: 500px;
        margin: 0 auto;

        .logo {
          img {
            width: auto;
            height: 100px;
          }
        }

        .title {
          font-size: 80px;
          font-weight: 500;
        }

        .text {
          line-height: 1.4;
          font-weight: 300;
        }

        .line {
          width: 130px;
          height: 2px;
          background: #fff;
          margin: 20px 0 60px;
        }

        .benefits-list {
          margin-bottom: 6rem;

          .item {
            margin-bottom: 10px;
            position: relative;

            .text {
              font-weight: 300;
              font-size: 16px;

              i.mdi {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  .form-side {
    background: #f6f7fb;

    > .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
