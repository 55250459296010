#debug-events-table pre {
  white-space: pre-wrap;
}

.code {
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
  line-height: 1.5;
  color: #333;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  margin: 0;
  overflow: auto;
}
