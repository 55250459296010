//
// side_menu.scss
//

.side-menu-custom {
    position: fixed;
    top: $navbar-height;
    left: 0;
    bottom: 0;
    z-index: 1001;
    background: #FFF;
    box-shadow: $shadow;
    overflow: hidden;
    width: $sidemenu-collapsed-width;

    &:hover, &.expanded {
        width: $sidemenu-expanded-width;

        .content .links-list .link-item .label {
            display: block;
        }
    }

    &.expanded {
        display: block;
    }

    > .content {
        padding: 15px;

        .links-list {
            margin: 10px 0;

            .link-item {
                display: flex;
                align-items: center;
                color: $gray-600;
                margin-bottom: 20px;

                @include transition(0.25s ease-in-out color);

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    color: $gray-800;
                }

                &.active {
                    color: $gray-900;
                }

                i {
                    font-size: 23px;
                    line-height: 30px;
                    width: 30px;
                    text-align: center;
                    display: block;

                    &.mdi-home-outline {
                        font-size: 26px;
                    }
                }

                .label {
                    font-size: 15px;
                    padding-left: 15px;
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .side-menu-custom {
        display: none;
        width: $sidemenu-expanded-width;
    }
}
