//
// _stats_card.scss
//

.dashboard-stats-card {
    .card-body {
        padding: 0;
    }

    .stats-item {
        padding: 25px 20px;
        text-align: center;

        .value {
            font-size: 4.5rem;
            font-weight: 300;
        }

        .label {
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 300;
        }
    }
}
