//
// CircularStd fonts
//

@font-face {
  font-family: 'CircularStd';
  src: local('CircularStd Book'), local('CircularStd-Book'), url('../../fonts/CircularStd/CircularStd-Book.woff') format('woff'), url('../../fonts/CircularStd/CircularStd-Book.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: local('CircularStd Medium'), local('CircularStd-Medium'), url('../../fonts/CircularStd/CircularStd-Medium.woff') format('woff'), url('../../fonts/CircularStd/CircularStd-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: local('CircularStd Bold'), local('CircularStd-Bold'), url('../../fonts/CircularStd/CircularStd-Bold.woff') format('woff'), url('../../fonts/CircularStd/CircularStd-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
