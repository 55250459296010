//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  background: $body-bg;
  color: $body-color;
}

#root .app {
  .app-container {
      padding-top: $navbar-height + 25px;
      padding-bottom: 25px;
      max-width: 1070px;
  }
}

a, a:not([href]), .fake-link {
  color: $blue;
  cursor: pointer;

  &:hover {
      color: $blue;
      text-decoration: none;
  }
}

i {
  line-height: 100%;
}

.clickable {
  cursor: pointer;
}

.bold-text {
  font-weight: 500;
}

.underlined-text {
  text-decoration: underline;
}

.uppercase-text {
  text-transform: uppercase;
}

.center-align {
  text-align: center;
}

.camera-image-holder {
  padding-top: 56.2%;
  background: $gray-100;
  position: relative;
  font-size: 0;

  img,
  .react-player,
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .alert {
    font-size: 0.9rem;
  }

  .placeholder {
    text-align: center;
    height: 100%;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    i {
      font-size: 3rem;
      color: #FFF;
    }
  }

  .camera-playback-error {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    z-index: 1;
  }

  .camera-skip-controls {
    top: 8px;
    left: 8px;
    right: 8px;
    z-index: 2;
    opacity: 0.8;
    transition: opacity 250ms ease-in;

    &:hover {
      opacity: 1;
    }
  }

  .canvas-parent {
    z-index: 8;
    bottom: 40px;
  }
}

.canvas-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

// Responsiveness to account for side-menu
@media screen and (max-width: 1300px) {
  #root .app {
    padding-left: $sidemenu-collapsed-width;
  }
}

@media screen and (max-width: 900px) {
  #root .app {
    padding-left: 0;
  }
}

.w-55 {
  width: 55%;
}
