.version-row {
  &:hover {
    .version-row__pin i {
      display: block;
    }
  }

  &__pin {
    width: 40px;

    i {
      opacity: 0.5;
      display: none;
      transition: opacity 100ms ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    &--pinned i {
      display: block;
      opacity: 1;
    }
  }
}