//
// custom-variables.scss
//

// Shadow
$shadow:                            0px 0px 15px 0px rgba(0,0,0,0.08);
$shadow-lg:                         0 0 45px 0 rgba(0, 0, 0, 0.12);

// Navbar Height
$navbar-height:                     60px;
$navbar-logo-height:                35px;

// Side Menu width
$sidemenu-collapsed-width:          60px;
$sidemenu-expanded-width:           220px;

// Footer height
$footer-height:                     60px;

// Menu item colors (Default-dark)
$menu-item:                       #cedce4;
$menu-item-hover:                 #ffffff;
$menu-item-active:                #ffffff;

// Menu item colors (light)
$menu-item-light:                 #6c757d;
$menu-item-light-hover:           #727cf5;
$menu-item-light-active:          #727cf5;

// Dark sidenav Menu item color
$sidebar-dark-menu-item:          #8391a2;
$sidebar-dark-menu-item-hover:    #bccee4;
$sidebar-dark-menu-item-active:   #ffffff;

// Navbar Search
$bg-navbar-search:                rgba(255,255,255,0.1);

// Helpbox
$help-box-light-bg:               rgba(255,255,255,0.07);
$help-box-dark-bg:                #727cf5;

// Font weight
$font-weight-semibold:              600;

// Dropdown Large
$dropdown-lg-width:                 320px;

// Page title color
$page-title-color:                  inherit;

// Nav-pill background
$nav-pills-bg:                    #eef2f7;

// Custom-accordion
$custom-accordion-title-color:    #313a46;

// Dragula demo background
$dragula-bg:                      #f7f9fb;

// Form wizard header background
$form-wizard-header-bg:           #eef2f7;

// Text title color
$text-title-color:                #102A43;

// card Loader background
$card-loader-bg:                  #313a46;

// Chat widget
$chat-primary-user-bg:            #fef5e4;
$chat-secondary-user-bg:          #f1f3fa;

// Apex chart
$apex-grid-color:                 #f9f9fd;

// Hero
$hero-bg: linear-gradient(to bottom,#8669ed,#727cf5);

// Card
$card-box-shadow:                 0px 0px 15px 0px rgba(0,0,0,0.05);
